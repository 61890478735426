import { SxProps } from '@mui/material';
import { lightTheme } from '../theme/index';

const { palette, spacing } = lightTheme;

const styles: Record<string, SxProps> = {
  bulletPoint: {
    display: 'flex',
    alignItems: 'center',
    color: palette.grey.main,
    svg: {
      width: { xs: '1rem', md: '1.25rem' },
      height: { xs: '1.25rem', sm: '1.5rem', md: '1.6rem' },
      alignSelf: 'flex-start'
    },
    mb: spacing(1),
    '&:last-child': {
      mb: 0
    }
  },

  bulletText: {
    padding: `0 0 0 ${spacing(0.5)}`,

    // added for auction page bold bullet points
    strong: {
      display: 'block'
    }
  },

  anchorTag: {
    color: palette.primary.main,
    cursor: 'pointer'
  },
  inactiveAnchor: {
    color: 'inherit',
    cursor: 'default',
    pointerEvents: 'none',
    textDecoration: 'none'
  },
  pointerText: {
    cursor: 'pointer'
  }
};

export default styles;
