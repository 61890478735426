import { Box, Divider, Grid, Typography, Link as MuiLink } from '@mui/material';
import Link from 'next/link';
import React, { useState } from 'react';
import { lightTheme } from '../../theme/index';
import { NextImage } from '../NextImage/index';
import SvgFoxtonsFilled from '../../icons/FoxtonsFilled';
import SvgFoxtonsBlack from '../../icons/FoxtonsBlack';
import styles from './styles';
import { LinkComponent } from '../../utils/MarkdownOverrides';
import Button from '../Button';
import { dynamicImport } from '../../utils/reactUtils';

const Dialog = dynamicImport(() => import('@mui/material/Dialog'));
const ClickAwayListener = dynamicImport(
  () => import('@mui/material/ClickAwayListener')
);
const Close = dynamicImport(() => import('../../icons/Close'));

const { spacing } = lightTheme;

export interface LibFooterWebsiteProps {
  linksData?: {
    modernSlaveryStatementsModal?: {
      title?: string;
      footerModalItems: {
        linkText?: string;
        href?: string;
      }[];
    };
    bottomSection?: {
      bottomSectionLinks?: {
        id?: string;
        label?: string;
        url?: string;
      }[];
    };
    footerSections?: {
      id?: string;
      title?: string;
      links?: {
        id?: string;
        label?: string;
        url?: string;
      }[];
    }[];
    socialMediaIcons?: {
      icons?: {
        link?: string;
        image?: {
          data?: {
            attributes?: {
              url?: string;
              alternativeText?: string;
            };
          };
        };
      }[];
    };
  };
}

function LibFooterWebsite({ linksData }: LibFooterWebsiteProps) {
  const [
    isModernSlaveryStatementsModalOpen,
    setIsModernSlaveryStatementsModalOpen
  ] = useState(false);

  return (
    <>
      <Box
        sx={styles.footerContainer}
        component="footer"
        className="footerContainer"
      >
        <Grid container spacing={spacing(1)} justifyContent="space-between">
          <Grid item md={2} sm={12} xs={12} sx={styles.logoGrid}>
            <Box sx={styles.logoBox}>
              <SvgFoxtonsFilled sx={styles.foxtonsLogo} />
              <SvgFoxtonsBlack sx={styles.logoBoxText} />
            </Box>
            <Box sx={styles.socialIconsBox}>
              {linksData?.socialMediaIcons?.icons?.map((logo) => (
                <Box sx={styles.iconInternalBox} key={logo?.link}>
                  <Link href={logo?.link || ''} prefetch={false}>
                    <NextImage
                      image={{
                        src: logo?.image?.data?.attributes?.url || '',
                        alt:
                          logo?.image?.data?.attributes?.alternativeText ||
                          'alt-text'
                      }}
                      unoptimized
                      style={{ height: 'auto', width: '100%' }}
                      sizes="25vw"
                    />
                  </Link>
                </Box>
              ))}
            </Box>
          </Grid>
          {linksData?.footerSections?.map((list) => (
            <Grid item md={2} xs={12} sm={6} key={`category_${list?.id}`}>
              <Typography
                sx={styles.footerSectionTitle}
                variant="subtitle1"
                component="h2"
              >
                {list?.title || ''}
              </Typography>

              {list?.links?.map((link) => (
                <Box key={link?.id} sx={styles.footerSectionLink}>
                  <Link href={link?.url || ''} prefetch={false}>
                    <Typography variant="subtitle2" component="p">
                      {link?.label}
                    </Typography>
                  </Link>
                </Box>
              ))}
            </Grid>
          ))}
        </Grid>
        <Divider variant="fullWidth" sx={styles.divider} />
        <Box sx={styles.footlinksBox}>
          {linksData?.bottomSection?.bottomSectionLinks?.map((link) => (
            <Box key={link?.id} sx={styles.footerLinks}>
              {link?.url?.indexOf('component:ModernSlaveryStatementsModal') ===
              0 ? (
                <MuiLink
                  onClick={() => setIsModernSlaveryStatementsModalOpen(true)}
                  sx={{ cursor: 'pointer' }}
                  component="button"
                  variant="subtitle2"
                >
                  {link?.label}
                </MuiLink>
              ) : (
                <LinkComponent href={link?.url} title={link?.label}>
                  <Typography variant="subtitle2" component="p">
                    {link?.label}
                  </Typography>
                </LinkComponent>
              )}
            </Box>
          ))}
        </Box>
      </Box>
      <Dialog open={isModernSlaveryStatementsModalOpen}>
        <ClickAwayListener
          onClickAway={() => setIsModernSlaveryStatementsModalOpen(false)}
        >
          <Box sx={styles.modalBox}>
            <Box sx={styles.titleBox}>
              {!linksData?.modernSlaveryStatementsModal?.title ? null : (
                <Typography variant="h2">
                  {linksData.modernSlaveryStatementsModal.title}
                </Typography>
              )}
              <Close
                sx={styles.closeIcon}
                onClick={() => setIsModernSlaveryStatementsModalOpen(false)}
              />
            </Box>

            <Divider sx={styles.dividerModal} />
            <Box sx={styles.modalContent}>
              <Box sx={styles.linkBox}>
                {linksData?.modernSlaveryStatementsModal?.footerModalItems?.map(
                  (link, index) => (
                    <LinkComponent
                      href={link?.href || ''}
                      prefetch={false}
                      style={styles.modalLinks}
                      key={`${link?.href}${index}`}
                    >
                      <Typography variant="subtitle2">
                        {link?.linkText || ''}
                      </Typography>
                    </LinkComponent>
                  )
                )}
              </Box>
              <Button
                variant="primary-dark"
                color="primary"
                sx={styles.closeModalButton}
                onClick={() => setIsModernSlaveryStatementsModalOpen(false)}
              >
                Close
              </Button>
            </Box>
          </Box>
        </ClickAwayListener>
      </Dialog>
    </>
  );
}

export const FooterWebsite = React.memo(LibFooterWebsite);

export default FooterWebsite;
