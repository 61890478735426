import { SxProps } from '@mui/material';
import { lightTheme } from '../../theme/index';

const { palette, spacing } = lightTheme;

const styles: Record<string, SxProps> = {
  modalBox: {
    width: {
      xs: '80vw',
      sm: '28.5rem'
    }
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',

    px: {
      xs: '1rem',
      sm: '1.25rem'
    },
    pt: {
      xs: '1.5rem',
      sm: '2rem'
    },
    pb: {
      xs: '2rem',
      sm: '2.5rem'
    }
  },
  linkBox: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '1rem'
  },
  modalLinks: {
    color: palette.primary.main,
    width: 'fit-content',
    fontSize: '1rem',
    lineHeight: 'normal',
    pt: '1rem'
  },
  closeModalButton: {
    mt: '2.5rem'
  },
  titleBox: {
    pt: '1.25rem',
    px: {
      xs: '1rem',
      sm: '1.25rem'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  closeIcon: {
    fill: palette.grey.main,
    width: '1.5rem',
    height: '1.5rem',
    cursor: 'pointer'
  },
  dividerModal: {
    border: `0.063rem solid ${palette.lightishBluish.main}`,
    mt: '1.25rem'
  },
  footerContainer: {
    width: '100%',
    height: 'auto',
    background: palette.primary['50'],
    /* paddingTop: {
      xs: '2.5rem',
      md: '5rem'
    },
    paddingLeft: {
      xs: '0.9375rem',
      md: '5rem'
    },
    paddingRight: {
      xs: '0.9375rem',
      md: '5rem'
    },
    paddingBottom: '1rem' */
    padding: {
      xs: `${spacing(2)} ${spacing(1)} ${spacing(1)}`,
      md: `${spacing(4)} ${spacing(4)} ${spacing(1)}`
    }
  },

  logoGrid: {
    display: 'flex',
    flexDirection: {
      xs: 'row',
      md: 'column'
    },
    alignItems: 'center',
    justifyContent: { xs: 'space-between', md: 'flex-start' }
  },

  logoBox: {
    mb: { xs: '0', md: '1.5rem' },
    display: 'flex',
    alignItems: 'flex-start',
    gap: '0.5rem',
    width: '100%'
  },

  iconInternalBox: {
    width: { xs: '1.5rem', md: '2.25rem' }
  },

  foxtonsLogo: {
    fontSize: { xs: '3rem', md: '3.125rem' },
    height: '3rem'
  },

  logoBoxText: {
    display: { xs: 'none', sm: 'block' },
    fontSize: '5rem',
    mt: '-1rem'
  },

  socialIconsBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: {
      xs: 'flex-end',
      md: 'flex-start'
    },
    gap: '0.5rem',
    width: '100%'
  },

  footerSectionTitle: {
    color: palette.primary.main,
    textDecoration: 'none',
    mb: spacing(1),
    fontWeight: 'bold'
  },

  footerSectionLink: {
    my: { xs: spacing(0.5), md: spacing(1) },
    a: {
      color: palette.grey.main,
      textDecoration: 'none',
      my: '1rem'
    }
  },

  divider: {
    borderBottomWidth: '0.0625rem',
    borderColor: palette.primary['500'],
    opacity: '0.4',
    margin: spacing(1)
  },

  footlinksBox: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    textAlign: 'center',
    mx: 'auto',
    my: '1.5rem',
    gap: { xs: '0.5rem', md: '1.5rem' }
  },

  footerLinksFirst: {
    width: { xs: '100%', md: 'auto' },
    mb: { xs: '0.5rem', md: '0' },
    a: {
      color: palette.grey.main,
      fontSize: '1rem',
      lineHeight: '1.25rem',
      fontWeight: '400',
      textDecoration: 'none'
    }
  },

  footerLinks: {
    display: 'flex',
    'a, button': {
      color: palette.grey.main,
      textDecoration: 'none'
    }
  }
};

export default styles;
