import React from 'react';
import { SvgIcon } from '@mui/material';

const SvgFoxtonsBlack = (props) => (
  <SvgIcon {...props} title="FoxtonsBlack">
    <svg
      viewBox="0 0 99 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M74.343 7.308h-3.05v16.909h3.704V12.425c1.09-.89 2.615-2.002 4.358-2.002 1.525 0 2.396 1.112 2.396 3.56v10.011h3.922V12.648c0-4.45-2.832-6.007-5.011-6.007-1.961 0-3.704.667-5.665 2.224l-.654-1.557ZM43.6 24.217l-5.83-8.788 5.613-8.788h-4.318L35.61 11.96l-3.454-5.32h-4.319l5.614 8.789-5.83 8.788h4.319l3.67-5.55 3.67 5.55h4.32ZM.457.781v23.435h4.086V13.949H10.9l1.362-3.57H4.543v-6.25h8.627L14.305.781H.457Z"
        fill={'black'}
      />
      <path
        d="M44.432 10.585v7.79c0 4.328 2.874 5.842 5.085 5.842.884 0 1.99-.217 2.874-.432l1.326-3.895c-.884.432-1.99 1.082-3.095 1.082-1.547 0-2.432-1.082-2.432-3.462v-6.708h3.759l1.105-3.03H48.19V3.446l-3.98 1.948v2.38L42 10.803h2.432v-.218ZM98.356 7.53c-1.477-.667-2.532-.89-4.22-.89-3.588 0-5.91 2.003-5.91 4.895 0 3.338 2.533 4.45 4.432 5.34 1.478.668 2.744 1.112 2.744 2.447 0 1.113-1.055 1.78-2.744 1.78-1.477 0-2.954-.667-3.798-1.112l-1.055 3.115c1.477.667 3.165 1.112 4.853 1.112 4.01 0 6.332-2.002 6.332-5.34 0-3.337-2.533-4.45-4.643-5.34-1.477-.667-2.744-1.112-2.744-2.224 0-1.335 1.267-1.558 2.322-1.558 1.477 0 2.954.445 4.431 1.113V7.53ZM61.442 6.64c-4.801 0-7.723 4.005-7.723 8.9 0 5.117 2.713 8.677 7.723 8.677 4.8 0 7.722-3.782 7.722-8.9 0-4.672-2.922-8.676-7.722-8.676Zm0 14.462c-2.923 0-3.966-2.892-3.966-5.562 0-2.67.835-5.562 3.966-5.562 2.922 0 3.965 2.892 3.965 5.562 0 2.67-1.043 5.562-3.965 5.562ZM20.43 6.64c-4.8 0-7.723 4.005-7.723 8.9 0 5.117 2.713 8.677 7.723 8.677 4.8 0 7.723-3.782 7.723-8.9 0-4.672-2.922-8.676-7.723-8.676Zm0 14.462c-2.922 0-3.966-2.892-3.966-5.562 0-2.67.835-5.562 3.966-5.562 2.922 0 3.966 2.892 3.966 5.562 0 2.67-1.044 5.562-3.966 5.562Z"
        fill={'black'}
      />
    </svg>
  </SvgIcon>
);

export default SvgFoxtonsBlack;
