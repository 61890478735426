import React from 'react';
import {
  Box,
  Typography,
  Link as MuiLink,
  useTheme,
  useMediaQuery
} from '@mui/material';
import Link from 'next/link';
import styles from './MarkdownOverridesStyles';
import useClientOnly from './customHooks/useClientOnly';
import { bindComponentOnClickHandler } from './index';
import { dynamicImport } from './reactUtils';

const Tooltips = dynamicImport(() => import('../../src/components/Tooltip'));
const TickmarkCircle = dynamicImport(() => import('../icons/TickmarkCircle'));

export function BulletPoint({ children }: { children: React.ReactNode }) {
  return (
    <Box sx={styles.bulletPoint} className="bulletPoint">
      <TickmarkCircle />
      <Typography variant="body1" sx={styles.bulletText}>
        {children}
      </Typography>
    </Box>
  );
}

const isExternalDomain = (href: string) => {
  switch (href?.[0] || '') {
    case '/':
    case '#':
    case '?':
    case '':
    case process.env.NEXT_PUBLIC_URL:
      return false;
    default:
      if (href.includes(window.location.host)) return false;

      return true;
  }
};

export function LinkComponent({ href, children, title, ...props }) {
  const { breakpoints } = useTheme();
  const isSmallScreen = useMediaQuery(breakpoints.down('md'));
  const { hasMounted } = useClientOnly();

  const handleOpen = () => {
    const componentName = href.replace(/component:/, '');
    const componentSplitName = componentName.split('-');

    if (componentSplitName[0] === 'SearchPropertyBox') {
      sessionStorage.setItem('showSearchWidget', 'true');
      sessionStorage.setItem('searchType', componentSplitName[1]);
      window.dispatchEvent(new Event('storage'));
    } else {
      bindComponentOnClickHandler(href);
    }
  };

  if (href.match(/^component:(\w)+$/)) {
    return (
      <MuiLink {...props} href={undefined} onClick={handleOpen}>
        {children}
      </MuiLink>
    );
  }

  if (href.match(/^component:SearchPropertyBox-\w+$/)) {
    return (
      <MuiLink
        {...props}
        href={undefined}
        onClick={handleOpen}
        sx={styles.pointerText}
      >
        {children}
      </MuiLink>
    );
  }

  if (href?.indexOf('##') === 0) {
    return (
      <Box
        sx={{ maxWidth: '10rem', display: 'inline-block' }}
        id="tooltip-block"
      >
        <Tooltips
          label={title}
          placement="top"
          arrow
          baseStyles={{ maxWidth: '20rem' }}
        >
          {children}
        </Tooltips>
      </Box>
    );
  }

  if (href?.toLowerCase().indexOf('tel:') === 0) {
    if (isSmallScreen) {
      return (
        <Link prefetch={false} {...props} href={href || ''}>
          {children}
        </Link>
      );
    }

    return (
      <Link
        prefetch={false}
        {...props}
        href={href || ''}
        style={styles.inactiveAnchor}
      >
        {children}
      </Link>
    );
  }

  return (
    <Link
      prefetch={false}
      target={hasMounted && isExternalDomain(href) ? '_blank' : '_self'}
      {...props}
      href={href || ''}
    >
      {children}
    </Link>
  );
}

const markdownOverrides = {
  // li: (props: { children: React.ReactNode }) => BulletPoint(props), ===>DO NOT REMOVE. Its for reference
  li: {
    component: BulletPoint
  },
  ul: {
    component: Box,
    props: { className: 'bulletPointBox' }
  },
  a: {
    component: LinkComponent,
    props: { style: styles.anchorTag }
  }
};

// basic overrides for rich text components in strapi
// to give a unified look and feel
// only define font size, line height and spacing here
export const commonRichTextOverrides = {
  ...markdownOverrides,
  h1: {
    component: Typography,
    props: { variant: 'h1', my: 2 }
  },
  h2: {
    component: Typography,
    props: { variant: 'h2', my: 2 }
  },
  h3: {
    component: Typography,
    props: { variant: 'h3', my: 2 }
  },
  h4: {
    component: Typography,
    props: { variant: 'h4', my: 2 }
  },
  h5: {
    component: Typography,
    props: { variant: 'h5', my: 1 }
  },
  h6: {
    component: Typography,
    props: { variant: 'h6', my: 1 }
  },
  p: {
    component: Typography,
    props: { variant: 'body1', mb: 1 }
  }
};

export default markdownOverrides;
